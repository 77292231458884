const CHATWOOT_BASEURL = 'https://app.chatwoot.com'

type AppGuideLocale = 'en' | 'fr'
const CHATWOOT_WEBSITE_TOKEN: Record<AppGuideLocale, string> = {
  en: 'XNpmAUZSYuyG7RLgoeYdq6F2',
  fr: 'sNTQd6DejJbSfMyKjsBHSAo8',
}

export function useChatwoot() {
  const { track } = useAnalytic()
  const { locale } = useI18n<any, AppGuideLocale>()
  const active = useState('cw-active', () => false)
  const route = useRoute()

  function init() {
    if (active.value)
      return

    active.value = true
    const script = document.createElement('script')

    script.src = `${CHATWOOT_BASEURL}/packs/js/sdk.js`
    script.async = true
    script.defer = true

    document.body.appendChild(script)
    window.chatwootSettings = {
      locale: locale.value,
    }
    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: CHATWOOT_WEBSITE_TOKEN[locale.value],
        baseUrl: CHATWOOT_BASEURL,
      })
    }

    window.addEventListener('chatwoot:ready', () => {
      window.$chatwoot.setLocale(locale.value)

      const bubbleElements = document.querySelectorAll('.woot-widget-bubble')
      bubbleElements.forEach((bubbleElement) => {
        bubbleElement.setAttribute('data-x-action', 'toggle')
        bubbleElement.setAttribute('data-x-name', 'chatwoot-bubble')
      })

      if (route.query.chatwoot === 'open')
        setTimeout(() => toggle('open'), 250)
    })
  }

  function changeLocale() {
    if (!active.value)
      return

    window.$chatwoot.websiteToken = CHATWOOT_WEBSITE_TOKEN[locale.value]
    const iframe = document.getElementById('chatwoot_live_chat_widget') as HTMLIFrameElement | null
    if (iframe)
      iframe.src = `${window.$chatwoot.baseUrl}/widget?website_token=${window.$chatwoot.websiteToken}`
  }

  function toggle(state?: 'open' | 'close') {
    if (!active.value)
      return

    window.$chatwoot.toggle(state)

    try {
      if (state === 'open')
        track('chatwoot:toggle:open')
      else if (state === 'close')
        track('chatwoot:toggle:close')
    }
    catch (err) {
      console.error(err)
    }
  }

  return {
    init,
    changeLocale,
    toggle,
  }
}

export function useChatwootOnlineAgents() {
  const onlineAgents = useState<boolean | null>('cw-online-agents', () => null)
  const fetched = useState('cw-online-agents-fetched', () => false)
  const pending = useState('cw-online-agents-pending', () => false)
  const interval = useState('cw-online-agents-interval', () => false)

  async function fetchOnlineAgents() {
    if (fetched.value || pending.value)
      return

    pending.value = true

    try {
      const response = await $fetch('/api/online-agents')
      onlineAgents.value = response.online
      fetched.value = true
    }
    catch (err) {
      console.error(err)
    }
    finally {
      pending.value = false
    }
  }

  onMounted(async () => {
    await nextTick()
    fetchOnlineAgents()

    if (interval.value)
      return

    interval.value = true
    setInterval(() => {
      if (pending.value)
        return

      fetched.value = false
      fetchOnlineAgents()
    }, 1000 * 60 * 6)
  })

  return onlineAgents
}
